import { IMAGE_VIEWER_OPEN, UPDATE_IMAGE_VIEWER } from './actions';

const initialState = {
  open: false,
  document: null
};

const imageViewerReducer = (state = initialState, action) => {
  switch (action.type) {
    case IMAGE_VIEWER_OPEN:
      return {
        ...state,
        open: action.open ? action.open : initialState.open,
        document: action.document ? action.document : initialState.document
      };
    case UPDATE_IMAGE_VIEWER:
      return {
        ...state,
        document: action.document
          ? { ...state.document, ...action.document }
          : state.document
      };
    default:
      return state;
  }
};

export default imageViewerReducer;
