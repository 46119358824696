import { lazy } from 'react';

import Loadable from 'components/Loadable';

const NotFound = Loadable(lazy(() => import('components/NotFound')));
const Unauthorized = Loadable(lazy(() => import('components/Unauthorized')));

const ErrorRoutes = {
  path: '/',
  children: [
    {
      path: '/unauthorized',
      element: <Unauthorized />
    },
    {
      path: '*',
      element: <NotFound />
    }
  ]
};

export default ErrorRoutes;
