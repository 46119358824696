import React from 'react';
import useAuth from 'hooks/useAuth';
import Avatar from './material-components/Avatar';

export default function Avi({ size = 40 }) {
  const { user, isLoggedIn } = useAuth();
  return isLoggedIn ? (
    <div id="namecard" style={{ width: size, height: size }}>
      <span id="centerpiece">
        {user?.first_name[0]}
        {user?.last_name[0]}
      </span>
    </div>
  ) : (
    <Avatar />
  );
}
