import * as constants from './actions';

const initialState = {
  isLoading: false,
  claims: null,
  error: null,
  claimRegistered: false
};

const claimsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.POST_CLAIM:
      return { ...state, claimRegistered: true, isLoading: false };
    case constants.GET_CLAIMS:
      return { ...state, claims: action.claims, isLoading: false };
    case constants.GET_GROUP_CLAIMS:
      return { ...state, claims: action.claims, isLoading: false };
    case constants.SET_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case constants.SET_LOADING:
      return { ...state, isLoading: true };
    default:
      return state;
  }
};

export default claimsReducer;
