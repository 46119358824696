import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import AdminGuard from 'utils/route-guard/AdminGuard';
import MemberProfileGuard from 'utils/route-guard/ProfileGuard';

const HomePage = Loadable(lazy(() => import('views/dashboard')));
const AppUserAccountProfile = Loadable(lazy(() => import('views/userProfile')));
const GroupPage = Loadable(lazy(() => import('views/group-profile')));
const MemberList = Loadable(lazy(() => import('views/members/all')));
const ClaimsList = Loadable(lazy(() => import('views/claims/claims-list')));
const PremiumList = Loadable(lazy(() => import('views/premiums')));
const AdminProfile = Loadable(lazy(() => import('views/admin-profile')));
const PolicyProfile = Loadable(lazy(() => import('views/user-policy')));
const GroupMembers = Loadable(lazy(() => import('views/members/group/'))); // for reps only
const AllPolicies = Loadable(lazy(() => import('views/policies/allPolicies')));
const AdminCommission = Loadable(lazy(() => import('views/commissions/admin')));

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/home',
      element: <HomePage />
    },
    {
      path: '/members',
      element: (
        <AdminGuard>
          <MemberList />
        </AdminGuard>
      )
    },
    {
      path: '/premiums',
      element: <PremiumList />
    },
    {
      path: 'claims/',
      element: <ClaimsList />
    },
    {
      path: 'policies/:id/:tab',
      element: <PolicyProfile />
    },
    {
      path: 'policies/:id',
      element: <PolicyProfile />
    },
    {
      path: '/admin/profile',
      element: <AdminProfile />
    },
    {
      path: '/user/account/:tab',
      element: (
        <MemberProfileGuard>
          <AppUserAccountProfile />
        </MemberProfileGuard>
      )
    },
    {
      path: '/user/:id/:tab',
      element: <AppUserAccountProfile />
    },
    {
      path: 'groups/:id',
      element: <GroupPage />
    },
    {
      path: 'admin/groups/:id',
      element: <GroupPage />
    },
    {
      path: 'groups/:id/policies',
      element: <GroupPage />
    },
    {
      path: 'rep/:id/members',
      element: <GroupMembers />
    },
    {
      path: '/policies',
      element: (
        <AdminGuard>
          <AllPolicies />
        </AdminGuard>
      )
    },
    {
      path: '/commissions',
      element: (
        <AdminGuard>
          <AdminCommission />
        </AdminGuard>
      )
    },
    {
      path: '/commissions/:tab',
      element: (
        <AdminGuard>
          <AdminCommission />
        </AdminGuard>
      )
    }
  ]
};

export default MainRoutes;
