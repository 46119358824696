import {
  ADD_DEPENDANTS,
  ADD_DEPENDANTS_FAILED,
  DELETE_DEPENDANT,
  GET_DEPENDANTS,
  UPDATE_DEPENDANT,
  SNACKBAR_OPEN
} from 'store/actions';
import axios from 'utils/axios';
import { rootApi } from 'store/store';

const DEPENDANTS_URL = '/dependants/';

export const addDepedants = (data) => async (dispatch) => {
  try {
    const res = await axios.post(DEPENDANTS_URL, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    dispatch({
      type: ADD_DEPENDANTS,
      dependant: res.data
    });
  } catch (err) {
    dispatch({
      type: ADD_DEPENDANTS_FAILED,
      error: Object.values(err)
    });
  }
};

export const addUserDependants = (id, data) => async (dispatch) => {
  try {
    const res = await axios.post(`${DEPENDANTS_URL}admin/${id}/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    dispatch({
      type: ADD_DEPENDANTS,
      dependant: res.data
    });
    rootApi.util.invalidateTags(['Single Policy', 'Single Policy Admin']);
  } catch (err) {
    dispatch({
      type: ADD_DEPENDANTS_FAILED,
      error: Object.values(err)
    });
  }
};

export const fetchDependants = () => async (dispatch) => {
  try {
    const res = await axios.get(DEPENDANTS_URL);
    dispatch({
      type: GET_DEPENDANTS,
      dependants: res.data
    });
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchUserDependants = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${DEPENDANTS_URL}admin/${id}`);
    dispatch({
      type: GET_DEPENDANTS,
      dependants: res.data
    });
  } catch (err) {
    throw new Error(err);
  }
};
export const updateDendant = (data) => async (dispatch) => {
  try {
    const res = await axios.put(`${DEPENDANTS_URL}${data.id}/`, data);
    dispatch({
      type: UPDATE_DEPENDANT,
      dependant: res.data
    });
    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      message: 'Dependant successfully updated.',
      variant: 'alert',
      alertSeverity: 'success'
    });
    rootApi.util.invalidateTags(['Single Policy', 'Single Policy Admin']);
  } catch (err) {
    throw new Error(err);
  }
};

export const patchDependant = (id, data) => async (dispatch) => {
  try {
    const res = await axios.patch(`${DEPENDANTS_URL}${id}/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    dispatch({
      type: UPDATE_DEPENDANT,
      dependant: res.data
    });
    rootApi.util.invalidateTags(['Single Policy', 'Single Policy Admin']);
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteDependant = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`${DEPENDANTS_URL}${id}/`);
    if (res.status === 204) {
      dispatch({
        type: DELETE_DEPENDANT,
        payload: { id }
      });
    }
    rootApi.util.invalidateTags(['Single Policy', 'Single Policy Admin']);
  } catch (err) {
    throw new Error(err);
  }
};
