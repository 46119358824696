// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const EDIT_USER = 'EDIT_USER';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const RESET_AUTH_ERROR = 'RESET_AUTH_ERROR';
export const GET_OPTIONS = 'SET_OPTIONS';
export const GET_OPTIONS_SUCCESS = 'GET_OPTIONS_SUCCESS';
export const GET_OPTIONS_FAILED = 'GET_OPTIONS_FAILED';
export const POST_OPTION = 'POST_OPTION';
export const GET_MEMBERS = 'GET_MEMBERS';
export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUPS_LOADING = 'GET_GROUPS_LOADING';
export const GET_GROUPS_FAILED = 'GET_GROUPS_FAILED';
export const GET_GROUP_CLAIMS = 'GET_GROUP_CLAIMS';
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCESS';
export const GET_MEMBERS_FAILED = 'GET_MEMBERS_FAILED';
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const RESET = 'RESET';
export const GET_USER = 'GET_USER';
export const GET_PERSONAL_DETAILS = 'GET_PERSONAL_DETAILS';
export const GET_USERS = 'GET_USERS';
export const GET_USER_FAILED = 'GET_USER_FAILED';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';
export const ADD_USERS = 'ADD_USERS';
export const ADD_USERS_FAILED = 'ADD_USERS_FAILED';
export const RESET_ADD_USERS_STATUS = 'RESET_ADD_USERS_STATUS';
export const GET_BENEFICIARIES = 'GET_BENEFICIARIES';
export const GET_BENEFICIARIES_FAILED = 'GET_BENEFICIARIES_FAILED';
export const REGISTER_BENEFICIARIES = 'REGISTER_BENEFICIARIES';
export const REGISTER_BENEFICIARIES_FAILED = 'REGISTER_BENEFICIARIES_FAILED';
export const PAY_PREMIUM = 'PAY_PREMIUM';
export const UPLOAD_CERTIFICATE = 'UPLOAD_CERTIFICATE';
export const UPLOAD_FAILED = 'UPLOAD_FAILED';
export const GET_PREMIUMS = 'GET_PREMIUMS';
export const GET_CLAIMS = 'GET_CLAIMS';
export const POST_CLAIM = 'POST_CLAIM';
export const RESET_STATUS = 'RESET_STATUS';
export const RESET_CURRENT_USER = 'RESET_CURRENT_USER';
export const RESET_USERS_ERROR = 'RESET_USERS_ERROR';

// group
export const REGISTER_GROUP = 'REGISTER_GROUP';
export const RESET_GROUP_STATE = 'RESET_GROUP_STATE';
export const ADD_REPS = 'ADD_REPS';
export const ADD_REPS_FAILED = 'ADD_REPS_FAILED';
export const ADD_ADMIN = 'ADD_ADMIN';
export const ADD_ADMIN_FAILED = 'ADD_ADMIN_FAILED';
export const RESET_REPS_STATUS = 'RESET_REPS_STATUS';
export const RESET_ADMIN_STATUS = 'RESET_ADMIN_STATUS';
export const ADD_GROUP_MEMBERS = 'ADD_GROUP_MEMBERS';
export const ADD_MEMBERS_FAILED = 'ADD_MEMBERS_FAILED';
export const RESET_ADD_MEMBERS_STATUS = 'RESET_ADD_MEMBERS_STATUS';
export const RESET_MEMBERS_ERRORS = 'RESET_MEMBERS_ERRORS';

// dependants
export const ADD_DEPENDANTS = 'ADD_DEPENDANTS';
export const ADD_DEPENDANTS_FAILED = 'ADD_DEPENDANTS_FAILED';
export const DELETE_DEPENDANT = 'DELETE_DEPENDANT';
export const UPDATE_DEPENDANT = 'UPDATE_DEPENDANT';
export const GET_DEPENDANTS = 'GET_DEPENDANTS';
export const RESET_DEPENDANTS_STATUS = 'RESET_DEPENDANTS_STATUS';

// transactions
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_BULK_TRANSACTIONS = 'GET_BULK_TRANSACTIONS';
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
export const UPDATE_BULK_TRANSACTION = 'UPDATE_BULK_TRANSACTION';
export const SEND_BULK_TRANSACATIONS = 'SEND_BULK_TRANSACTIONS';
export const SEND_BULK_TRANSACATIONS_FAILED = 'SEND_BULK_TRANSACTIONS_FAILED';
export const SEND_SINGLE_TRANSACATIONS = 'SEND_SINGLE_TRANSACTIONS';
export const SEND_SINGLE_TRANSACATIONS_FAILED =
  'SEND_SINGLE_TRANSACTIONS_FAILED';
export const FILTER_TRANSACTIONS = 'FILTER_TRANSACTIONS';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const MENU_TYPE = '@customization/MENU_TYPE';
export const PRESET_COLORS = '@customization/PRESET_COLORS';
export const THEME_LOCALE = '@customization/THEME_LOCALE';
export const THEME_RTL = '@customization/THEME_RTL';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_OUTLINED_FILLED = '@customization/SET_OUTLINED_FILLED';

// action - snackbar
export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN';

// Premiums Search
export const SEARCH_PREMIUMS = 'SEARCH_PREMIUMS';
export const RESET_PREMIUMS_RESULTS = 'RESET_PREMIUMS_RESULTS';

export const SWITCH_MEMBERS = 'SWITCH_MEMBERS';
export const RESET_SWITCH_STATUS = 'RESET_SWITCH_STATUS';

// action - imageviewer
export const IMAGE_VIEWER_OPEN = 'OPEN_IMAGE_VIEWER';
export const UPDATE_IMAGE_VIEWER = 'UPDATE_IMAGE_VIEWER';

// action - documents
export const ADD_BENEFICIARY_DOCUMENT = 'ADD_BENEFICIARY_DOCUMENT';
export const ADD_DEPENDANT_DOCUMENT = 'ADD_DEPENDANT_DOCUMENT';
export const UPDATE_BENEFICIARY_DOCUMENT = 'UPDATE_BENEFICIARY_DOCUMENT';
export const UPDATE_DEPENDANT_DOCUMENT = 'UPDATE_DEPENDANT_DOCUMENT';

export const UPDATE_BENEFICIARY_DOCUMENT_FAILED =
  'UPDATE_BENEFICIARY_DOCUMENT_FAILED';
export const UPDATE_DEPENDANT_DOCUMENT_FAILED =
  'UPDATE_DEPENDANT_DOCUMENT_FAILED';
