import { Button, Grid, Link, Typography } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { useDispatch } from 'react-redux';
import usersService from 'services/users.service';
import { verifyPhoneNumber } from 'services/verifyPhone.service';
import { SNACKBAR_OPEN } from 'store/actions';
import { useResendPhoneVerifyMutation } from 'store/store';

export default function OtpForm({ handleModalOpen }) {
  const { user, updateUser } = useAuth();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [verified, setVerified] = useState(false);
  const [resendPhoneVerify, result] = useResendPhoneVerifyMutation();
  const {
    isLoading,
    isError,
    isSuccess,
    error: resendError,
    reset,
    data
  } = result;

  const handleChange = (value) => {
    setOtp(value);
    setError('');
  };
  const handleError = (err) => {
    setError(
      (err?.non_field_errors && err?.non_field_errors[0]) || err.message
    );
  };
  const handleSuccess = () => {
    setVerified(true);
    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      message: 'Phone number verified Successfully',
      variant: 'alert',
      alertSeverity: 'success'
    });
    // dispatch(usersService.getPersonalDetails());
    updateUser();
    setTimeout(() => {
      handleModalOpen();
    }, 1500);
  };
  const handleSubmit = () => {
    try {
      verifyPhoneNumber(otp, handleSuccess, handleError);
    } catch (err) {
      console.log('failed');
    }
  };
  const resendToken = () => {
    resendPhoneVerify();
  };
  useEffect(() => {
    if (otp.length === 6) {
      handleSubmit();
    }
  }, [otp]);
  useEffect(() => {
    resendToken();
  }, []);
  useEffect(() => {
    if (isSuccess) {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        message: 'A verification code has been sent to your phone',
        variant: 'alert',
        alertSeverity: 'success'
      });
    }
    if (isError) {
      const errorMessage =
        resendError?.status === 429
          ? 'You have tried too many times. Try again later'
          : 'Request failed.Try again.';
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        message: errorMessage,
        variant: 'alert',
        alertSeverity: 'error'
      });
    }
  }, [isSuccess, isError, error]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography color="primary" variant="h2" align="center">
          Verify Phone Number
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color="secondary" variant="subtitle1" align="center">
          You have received a verification code on{' '}
          <strong>
            <u>{user?.mobile}</u>
          </strong>
        </Typography>
      </Grid>
      <Grid item xs={12} align="center">
        <ReactCodeInput
          type="number"
          fields={6}
          value={otp}
          onChange={handleChange}
          onSubmit={handleSubmit}
          isValid={!error}
        />
        <Typography
          color="error"
          sx={{ marginTop: 1 }}
          variant="subtitle2"
          align="center"
        >
          {error}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color="primary" variant="subtitle1" align="center">
          Did not receive verification code? {'   '}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link component="button" variant="body2" onClick={resendToken}>
            Resend Code
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
}
