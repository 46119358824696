const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  basename: '',
  defaultPath: '/',
  optionsPath: '/options',
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  outlinedFilled: true,
  theme: 'light',
  presetColor: 'theme5',
  i18n: 'en',
  rtlLayout: false,
  jwt: {
    secret: 'SECRET-KEY', // change
    timeout: '1 days'
  }
};

export default config;
