// action - state management
import {
  LOGIN,
  LOGOUT,
  REGISTER,
  LOGIN_FAILED,
  EDIT_USER,
  RESET_AUTH_ERROR
} from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const accountReducer = (state, action) => {
  switch (action.type) {
    case REGISTER: {
      const { user } = action.payload;
      return {
        ...state,
        user
      };
    }
    case LOGIN: {
      const { user } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        error: null,
        user
      };
    }
    case LOGIN_FAILED:
      return { ...state, error: action.error };
    case LOGOUT: {
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null
      };
    }
    case RESET_AUTH_ERROR:
      return { ...state, error: null };
    case EDIT_USER:
      return { ...state, user: action.payload.user };
    default: {
      return { ...state };
    }
  }
};

export default accountReducer;
