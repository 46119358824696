import {
  Dialog,
  Box,
  Grid,
  Stack,
  IconButton,
  Slide,
  Button
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { IconX } from '@tabler/icons';
import { forwardRef } from 'react';
import useAuth from 'hooks/useAuth';

export const Transition = forwardRef((props, refs) => (
  <Slide direction="up" ref={refs} {...props} />
));

export default function MainDialog({
  open,
  handleModalOpen,
  children,
  width = 'md',
  isOptional = true,
  logoutOption = false
}) {
  const { logout } = useAuth();
  return (
    <Dialog
      sx={{ zIndex: 1200 }}
      open={open}
      TransitionComponent={Transition}
      onClose={handleModalOpen}
      fullWidth
      maxWidth={width}
      scroll="paper"
    >
      {isOptional && (
        <Stack direction="row" justifyContent="flex-end">
          <IconButton
            size="small"
            onClick={() => handleModalOpen(true)}
            color="primary"
          >
            <IconX size="20px" />
          </IconButton>
        </Stack>
      )}
      {logoutOption && (
        <Stack direction="row" justifyContent="flex-end">
          <Button size="small" onClick={logout} color="primary">
            logout
          </Button>
        </Stack>
      )}
      <Box sx={{ p: 4 }} alignContent="center">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

MainDialog.propTypes = {
  open: PropTypes.bool,
  handleModalOpen: PropTypes.func,
  children: PropTypes.node,
  width: PropTypes.string
};
