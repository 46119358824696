import emailjs from '@emailjs/browser';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { IconX } from '@tabler/icons';
import CustomAlert from 'components/CustomAlert';
import { useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import * as Yup from 'yup';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiDialog-paper': {
    padding: 0
  },
  '& .MuiDialog-container': {
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  }
}));

export default function FeedbackForm({ toggleFeedback, openFeedback }) {
  const theme = useTheme();
  const { isLoggedIn, user } = useAuth();
  const dispatch = useDispatch();
  const [request, setRequest] = useState('');

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    message: Yup.string().required('Please provide your feedback')
  });

  const formik = useFormik({
    initialValues: {
      name: user ? `${user.first_name} ${user.last_name}` : '',
      message: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setRequest('pending');
        const res = await emailjs.send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          { ...values, email: process.env.REACT_APP_EMAIL_USER },
          process.env.REACT_APP_PUBLIC_KEY
        );
        if (res.text === 'OK') {
          setRequest('successful');
          toggleFeedback();
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            variant: 'alert',
            alertSeverity: 'success',
            message: 'Your feedback has been successfully sent'
          });
        }
      } catch (e) {
        formik.setErrors({ submit: 'something went wrong. please try again' });
        throw new Error(e);
      } finally {
        setRequest('');
      }
    }
  });

  return (
    <StyledDialog
      open={openFeedback}
      onClose={toggleFeedback}
      maxWidth="xs"
      sx={{ minWidth: '300px' }}
      PaperProps={{
        sx: {
          bottom: 35,
          right: 15
        }
      }}
    >
      <DialogTitle
        sx={{
          mb: 2,
          p: 2,
          background: theme.palette.primary.main,
          color: '#fff'
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h3" gutterBottom sx={{ color: '#fff' }}>
            Customer Support
          </Typography>
          <IconButton onClick={toggleFeedback} sx={{ color: '#fff' }}>
            <IconX />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              We value your feedback
              <SentimentSatisfiedAltIcon
                sx={{ mb: -0.7, ml: 0.5, color: theme.palette.orange.dark }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                {formik.errors.submit && (
                  <Grid item xs={12}>
                    <CustomAlert severity="error">
                      {formik.errors.submit}
                    </CustomAlert>
                  </Grid>
                )}

                {!isLoggedIn && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      type="text"
                      size="small"
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Message"
                    name="message"
                    multiline
                    rows={5}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.message && Boolean(formik.errors.message)
                    }
                    helperText={formik.touched.message && formik.errors.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="center">
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={request === 'pending'}
                      sx={{ px: { sm: 8 } }}
                    >
                      {request === 'pending' && (
                        <CircularProgress
                          size="1.5rem"
                          sx={{ mr: 1 }}
                          color="primary"
                        />
                      )}
                      {request === 'pending' ? 'submitting' : 'submit'}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
}
