import * as constants from './actions';

const initialState = {
  beneficiaries: null,
  error: null,
  success: null,
  percentage: 0
};
const beneficiariesReducer = (state = initialState, action) => {
  const { type, error, success, beneficiaries } = action;
  switch (type) {
    case constants.GET_BENEFICIARIES: {
      let actualPct = 0;
      if (beneficiaries.length) {
        actualPct = beneficiaries?.reduce(
          (acc, curr) => acc + Number(curr.percentage),
          0
        );
      }
      return { ...state, beneficiaries, percentage: actualPct };
    }
    case constants.GET_BENEFICIARIES_FAILED:
      return { ...state, error };
    case constants.REGISTER_BENEFICIARIES: {
      const newPercentage = [beneficiaries]?.reduce(
        (acc, curr) => acc + Number(curr.percentage),
        0
      );
      return {
        ...state,
        success,
        beneficiaries: [...state.beneficiaries, beneficiaries],
        percentage: +state.percentage + newPercentage
      };
    }
    case constants.REGISTER_BENEFICIARIES_FAILED:
      return { ...state, error };

    case constants.UPDATE_BENEFICIARY_DOCUMENT: {
      const { documents } = action;
      return {
        ...state,
        beneficiaries: state.beneficiaries.map((item) => {
          if (item?.documents?.id === documents?.id) {
            item.documents = documents;
          }
          return item;
        })
      };
    }
    case constants.ADD_BENEFICIARY_DOCUMENT: {
      const { documents, id } = action;
      return {
        ...state,
        beneficiaries: state.beneficiaries.map((item) => {
          if (item?.id === id) {
            item.documents = documents;
          }
          return item;
        })
      };
    }
    default:
      return state;
  }
};

export default beneficiariesReducer;
