import * as constants from 'store/actions';
import axios from 'utils/axios';
import authHeader from './auth-header';

const checkUser = async (nationalId) => {
  const response = await axios.get(`/users/nationalId/${nationalId}/`);
  return response;
};

const getPersonalDetails = () => async (dispatch) => {
  try {
    const response = await axios.get('/users/details/', {
      headers: {
        Authorization: authHeader()
      }
    });
    dispatch({
      type: constants.GET_PERSONAL_DETAILS,
      data: response.data
    });
  } catch (err) {
    console.log(err);
  }
};

const getUser = (userId) => async (dispatch) => {
  try {
    const response = await axios.get(`users/all/${userId}/`, {
      headers: {
        Authorization: authHeader()
      }
    });
    dispatch({
      type: constants.GET_USER,
      user: response.data
    });
  } catch (err) {
    console.log(err);
  }
};

const updateUser = (data) => async (dispatch) => {
  try {
    const response = await axios.put('/users/details/', data, {
      headers: {
        Authorization: authHeader()
      }
    });
    dispatch({
      type: constants.EDIT_USER,
      user: response.data
    });
  } catch (err) {
    console.error(err);
  }
};

const getUsers = () => async (dispatch) => {
  dispatch({ type: constants.SET_LOADING });
  try {
    const response = await axios.get('users/all/', {
      headers: {
        Authorization: authHeader()
      }
    });
    dispatch({
      type: constants.GET_USERS,
      users: response.data
    });
  } catch (err) {
    dispatch({
      type: constants.GET_USER_FAILED,
      error: Object.values(err)
    });
  }
};

const addUsers = (mode, data) => async (dispatch) => {
  try {
    const response = await axios.post('users/all/', data, {
      headers: {
        Authorization: authHeader()
      }
    });
    dispatch({
      type: constants.ADD_GROUP_MEMBERS,
      members: response.data,
      mode
    });
  } catch (e) {
    // extracting the error msgs. error response comes back as an array of objects
    let error = new Set();
    if (e.length) {
      // eslint-disable-next-line
      for (const err of e) {
        if (Object.keys(err).length) {
          error.add(...Object.values(err)[0]);
        }
      }
    } else {
      error = e;
    }
    dispatch({
      type: constants.ADD_MEMBERS_FAILED,
      error: [...error],
      mode
    });
  }
};

const usersService = {
  getUser,
  getUsers,
  checkUser,
  addUsers,
  getPersonalDetails,
  updateUser
};

export default usersService;
