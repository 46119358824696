import axios from 'utils/axios';
import * as constants from 'store/actions';
import { rootApi } from 'store/store';
import authHeader from './auth-header';

const getBeneficiaries = () => async (dispatch) => {
  try {
    const response = await axios.get(`/beneficiaries/`, {
      headers: {
        Authorization: authHeader()
      }
    });
    dispatch({
      type: constants.GET_BENEFICIARIES,
      beneficiaries: response.data
    });
  } catch (error) {
    dispatch({
      type: constants.GET_BENEFICIARIES_FAILED,
      error
    });
  }
};

const getUserBeneficiaries = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/beneficiaries/admin/${id}`, {
      headers: {
        Authorization: authHeader()
      }
    });
    dispatch({
      type: constants.GET_BENEFICIARIES,
      beneficiaries: response.data
    });
  } catch (error) {
    dispatch({
      type: constants.GET_BENEFICIARIES_FAILED,
      error
    });
  }
};

const registerBeneficiaries = (values) => async (dispatch) => {
  try {
    const response = await axios.post(`/beneficiaries/`, values.beneficiaries, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response.status === 201) {
      dispatch({
        type: constants.REGISTER_BENEFICIARIES,
        success: response.statusText,
        beneficiaries: response.data
      });
      dispatch(
        rootApi.util.invalidateTags(['Single Policy', 'Single Policy Admin'])
      );
      dispatch({
        type: constants.SNACKBAR_OPEN,
        open: true,
        message: 'Beneficiaries successfully updated.',
        variant: 'alert',
        alertSeverity: 'success'
      });
    } else if (response.status !== 201) {
      dispatch({
        type: constants.REGISTER_BENEFICIARIES_FAILED,
        error: response.statusText
      });
      dispatch({
        type: constants.SNACKBAR_OPEN,
        open: true,
        message: 'Operation failed. Please check your input',
        variant: 'alert',
        alertSeverity: 'error'
      });
    }
  } catch (error) {
    console.error('err', error);
  }
};

const registerUserBeneficiaries = (id, values) => async (dispatch) => {
  try {
    const response = await axios.post(
      `/beneficiaries/admin/${id}/`,
      values.beneficiaries
    );

    if (response.status === 201) {
      dispatch({
        type: constants.REGISTER_BENEFICIARIES,
        success: response.statusText,
        beneficiaries: response.data
      });
      dispatch(
        rootApi.util.invalidateTags(['Single Policy', 'Single Policy Admin'])
      );
      dispatch({
        type: constants.SNACKBAR_OPEN,
        open: true,
        message: 'Beneficiaries successfully updated.',
        variant: 'alert',
        alertSeverity: 'success'
      });
    } else if (response.status !== 201) {
      dispatch({
        type: constants.REGISTER_BENEFICIARIES_FAILED,
        error: response.statusText
      });
      dispatch({
        type: constants.SNACKBAR_OPEN,
        open: true,
        message: 'Operation failed. Please check your input',
        variant: 'alert',
        alertSeverity: 'error'
      });
    }
  } catch (error) {
    console.error('err', error);
  }
};

const editBeneficiary = (beneficiary, id) => async (dispatch) => {
  try {
    const response = await axios.patch(`/beneficiaries/${id}/`, beneficiary);
    if (response.status === 200) {
      dispatch(getBeneficiaries());
      dispatch(
        rootApi.util.invalidateTags(['Single Policy', 'Single Policy Admin'])
      );
      dispatch({
        type: constants.SNACKBAR_OPEN,
        open: true,
        message: 'Update Successful!',
        variant: 'alert',
        alertSeverity: 'success'
      });
    } else {
      dispatch({
        type: constants.SNACKBAR_OPEN,
        open: true,
        message: 'Update failed. Please try again.',
        variant: 'alert',
        alertSeverity: 'error'
      });
    }
  } catch (e) {
    console.error(e);
  }
};

const deleteBeneficiary = (beneficiary) => async (dispatch) => {
  try {
    const response = await axios.delete(`/beneficiaries/${beneficiary.id}/`);
    if (response.status === 204) {
      dispatch({
        type: constants.SNACKBAR_OPEN,
        open: true,
        message: 'Beneficiary deleted successfully',
        variant: 'alert',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        alertSeverity: 'success'
      });
      dispatch(getBeneficiaries());
      dispatch(
        rootApi.util.invalidateTags(['Single Policy', 'Single Policy Admin'])
      );
    }
  } catch (e) {
    console.error(e);
  }
};

const beneficiariesService = {
  getBeneficiaries,
  getUserBeneficiaries,
  registerBeneficiaries,
  registerUserBeneficiaries,
  editBeneficiary,
  deleteBeneficiary
};
export default beneficiariesService;
