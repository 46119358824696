// assets
import {
  IconDashboard,
  IconColorSwatch,
  IconReceiptRefund
} from '@tabler/icons';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

const other = {
  id: 'grouplife site-map',
  type: 'group',
  children: [
    {
      id: 'home',
      title: 'Dashboard',
      type: 'item',
      url: '/home',
      icon: IconDashboard,
      external: false,
      target: false
    },
    {
      id: 'user',
      title: 'Profile',
      type: 'item',
      url: 'user/account/profile',
      icon: AccountBoxIcon,
      external: false,
      target: false
    },
    {
      id: 'claims',
      title: 'Claims',
      type: 'item',
      url: '/claims',
      icon: IconReceiptRefund,
      external: false,
      target: false
    },
    {
      id: 'BenefitOptions',
      title: 'Select a Benefit Option',
      type: 'item',
      url: '/options',
      icon: IconColorSwatch,
      external: false,
      target: false
    }
  ]
};

export default other;
