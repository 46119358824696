import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Alert, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

function CustomAlert({ children, severity }) {
  const theme = useTheme();
  const icons = {
    error: <ErrorOutlineIcon />,
    success: <DoneAllIcon />
  };

  const bgSX = {
    success: {
      borderColor: theme.palette.success.dark,
      color: theme.palette.success.dark
    },
    error: {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.dark
    }
  };
  return (
    <Alert
      severity={severity}
      icon={icons[severity]}
      variant="contained"
      sx={bgSX[severity]}
    >
      {children}
    </Alert>
  );
}

CustomAlert.propTypes = {
  children: PropTypes.node,
  severity: PropTypes.string
};

export default CustomAlert;
