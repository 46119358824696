import * as constants from './actions';

const defaultOptions = {
  isLoading: false,
  allOptions: [],
  // add error'
  postMsg: ''
};

const optionsReducer = (state = defaultOptions, action) => {
  switch (action.type) {
    case constants.GET_OPTIONS:
      return { ...state, isLoading: true };
    case constants.GET_OPTIONS_SUCCESS:
      return { ...state, isLoading: false, allOptions: action.options };
    case constants.POST_OPTION:
      return { ...state, isLoading: false, postMsg: action.message };
    default:
      return state;
  }
};

export default optionsReducer;
