import React from 'react';
import Routes from 'routes';

import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/system';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import themes from 'themes';

import { JWTProvider } from 'contexts/JWTContext';
import Snackbar from 'components/material-components/Snackbar';

function App() {
  const customization = useSelector((state) => state.customization);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <JWTProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Routes />
            <Snackbar />
          </LocalizationProvider>
        </JWTProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
