import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  useMediaQuery,
  Collapse,
  Button,
  IconButton,
  Alert
} from '@mui/material';

// project imports
import Breadcrumbs from 'components/material-components/Breadcrumbs';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';

// assets
import { IconChevronRight } from '@tabler/icons';
import Feedback from 'layout/feedback';
import useRequired from 'hooks/useRequired';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import CloseIcon from '@mui/icons-material/Close';
import useRoles from 'hooks/useRoles';
import Sidebar from './Sidebar';
import Header from './Header';
import { VerifyPhone } from './Banner/VerifyPhone';
// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      [theme.breakpoints.up('md')]: {
        marginLeft: -(drawerWidth - 20),
        width: `calc(100% - ${drawerWidth}px)`
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: '20px',
        width: `calc(100% - ${drawerWidth}px)`,
        padding: '16px'
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
        width: `calc(100% - ${drawerWidth}px)`,
        padding: '16px',
        marginRight: '10px'
      }
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down('md')]: {
        marginLeft: '20px'
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px'
      }
    })
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const location = useLocation();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
  // check role
  const { role } = useRoles();
  const isMember = role && ['member', 'groupRep'].includes(role);
  // benefiary and dependant info alert states
  const { hasBeneficiaries, hasDependants } = useRequired();
  const [showDependantAlert, setDependantAlert] = useState(true);

  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  useEffect(() => {
    dispatch({ type: SET_MENU, opened: !matchDownMd });
    // eslint-disable-next-line
  }, [matchDownMd]);

  const hideDependantAlert = () => {
    setDependantAlert(false);
    localStorage.setItem('requireDependants', false);
  };
  useEffect(() => {
    const showDependantOption = localStorage.getItem('requireDependants');
    if (showDependantOption) {
      setDependantAlert(JSON.parse(showDependantOption));
    }
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened
            ? theme.transitions.create('width')
            : 'none'
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      {location.pathname !== '/options' && (
        <Sidebar
          drawerOpen={leftDrawerOpened}
          drawerToggle={handleLeftDrawerToggle}
        />
      )}
      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened}>
        {/* Mobile verification alert */}
        <VerifyPhone />
        {/* Beneficiary add alert */}
        {isMember && (
          <Collapse in={!hasBeneficiaries}>
            <Alert
              sx={{
                marginBottom: 1,
                backgroundColor: theme.palette.orange.light
              }}
              variant="standard"
              severity="error"
              action={
                <>
                  <Button
                    startIcon={<PersonAddAltIcon />}
                    href="/user/account/beneficiaries"
                    variant="contained"
                    size="small"
                  >
                    Add Beneficiary
                  </Button>
                  <IconButton aria-label="close" color="inherit" size="small">
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </>
              }
            >
              You haven&apos;t provided beneficiary details. Kindly add to
              activate your policy
            </Alert>
          </Collapse>
        )}
        {/* Dependant alert */}
        {/* {isMember && (
          <Collapse in={!hasDependants && showDependantAlert}>
            <Alert
              sx={{
                marginBottom: 1
              }}
              variant="standard"
              severity="info"
              action={
                <>
                  <Button
                    startIcon={<PersonAddAltIcon />}
                    href="/user/account/dependants"
                    variant="contained"
                  >
                    Add Dependant
                  </Button>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={hideDependantAlert}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </>
              }
            >
              Do you have any dependants? You can add a spouse and upto 4
              children.
            </Alert>
          </Collapse>
        )} */}
        {/* breadcrumb */}
        {(location.pathname === '/home' ||
          location.pathname === '/options') && (
          <Breadcrumbs
            separator={IconChevronRight}
            navigation={navigation}
            icon
            title
            rightAlign
          />
        )}
        <Outlet />
        <Feedback />
      </Main>
    </Box>
  );
};

export default MainLayout;
