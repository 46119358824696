import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar as MuiAppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  useScrollTrigger,
  ButtonBase
} from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
// assets
import { IconLogin, IconUserPlus } from '@tabler/icons';
// project imports
import Logo from 'components/Logo';
import config from 'config';
import { cloneElement, useState } from 'react';
import { Link } from 'react-router-dom';

function ElevationScroll({ children, window }) {
  const theme = useTheme();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window
  });
  const darkBorder = theme.palette.grey[200];

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
    style: {
      backgroundColor: theme.palette.background.default,
      // borderBottom: trigger ? 'none' : '1px solid',
      borderColor: trigger ? '' : darkBorder,
      color: theme.palette.text.dark
    }
  });
}

// ==============================|| MINIMAL LAYOUT APP BAR ||============================== //

const AppBar = ({ ...others }) => {
  const [drawerToggle, setDrawerToggle] = useState(false);
  /** Method called on multiple components with different event types */
  const drawerToggler = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerToggle(open);
  };

  return (
    <ElevationScroll {...others}>
      <MuiAppBar>
        <Container>
          <Toolbar sx={{ padding: 0 }}>
            <Typography component="div" sx={{ flexGrow: 1, textAlign: 'left' }}>
              <ButtonBase
                disableRipple
                component={Link}
                to={config.defaultPath}
              >
                <Logo />
              </ButtonBase>
            </Typography>
            <Stack
              direction="row"
              sx={{ display: { xs: 'none', sm: 'block' } }}
              spacing={2}
            >
              <Button color="inherit" component={Link} to="/register">
                Sign Up
              </Button>
              <Button color="inherit" component={Link} to="/login">
                Sign In
              </Button>
              <Button color="inherit" component={Link} to="/create-group">
                Create Group
              </Button>
              <Button
                component={Link}
                to="/claim"
                disableElevation
                variant="contained"
                color="secondary"
              >
                Raise Claim
              </Button>
            </Stack>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
              <IconButton
                color="inherit"
                onClick={drawerToggler(true)}
                size="large"
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="top"
                open={drawerToggle}
                onClose={drawerToggler(false)}
              >
                <Box
                  sx={{
                    width: 'auto'
                  }}
                  role="presentation"
                  onClick={drawerToggler(false)}
                  onKeyDown={drawerToggler(false)}
                >
                  <List>
                    <Link style={{ textDecoration: 'none' }} to="/register">
                      <ListItemButton component="a">
                        <ListItemIcon>
                          <AppRegistrationIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sign Up" />
                      </ListItemButton>
                    </Link>
                    <Link style={{ textDecoration: 'none' }} to="/login">
                      <ListItemButton component="a">
                        <ListItemIcon>
                          <IconLogin />
                        </ListItemIcon>
                        <ListItemText primary="Sign In" />
                      </ListItemButton>
                    </Link>
                    <Link style={{ textDecoration: 'none' }} to="/create-group">
                      <ListItemButton component="a">
                        <ListItemIcon>
                          <IconUserPlus />
                        </ListItemIcon>
                        <ListItemText primary="Create Group" />
                      </ListItemButton>
                    </Link>
                  </List>
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </MuiAppBar>
    </ElevationScroll>
  );
};

export default AppBar;
