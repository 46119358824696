import * as constants from './actions';

const initialState = {
  isLoading: false,
  premiumStatus: null,
  error: null,
  premiums: null,
  searchedPremiums: null,
  allPremiums: null,
  searchText: ''
};

const paymentsReducer = (state = initialState, action) => {
  const { premiumStatus, error } = action;
  switch (action.type) {
    case constants.SET_LOADING:
      return { ...state, isLoading: true };
    case constants.RESET:
      return initialState;
    case constants.PAY_PREMIUM:
      return { ...state, isLoading: false, premiumStatus };
    case constants.SET_ERROR:
      return { ...state, isLoading: false, error };
    case constants.GET_PREMIUMS:
      return {
        ...state,
        premiums: action.premiums,
        allPremiums: action.premiums
      };
    case constants.SEARCH_PREMIUMS:
      return {
        ...state,
        premiums: action.premiums,
        searchText:
          action.premiums.length === 0
            ? 'No results found.'
            : `${action.premiums.length} search result${
                action.premiums.length === 1 ? '' : 's'
              }`
      };
    case constants.RESET_PREMIUMS_RESULTS:
      return {
        ...state,
        premiums: state.allPremiums,
        searchedPremiums: null,
        searchText: ''
      };
    default:
      return state;
  }
};

export default paymentsReducer;
