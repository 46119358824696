// assets
import {
  IconDashboard,
  IconColorSwatch,
  IconReceiptRefund,
  IconReceipt
} from '@tabler/icons';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

const adminMenu = {
  items: [
    {
      id: 'grouplife site-map',
      type: 'group',
      children: [
        {
          id: 'home',
          title: 'Dashboard',
          type: 'item',
          url: '/home',
          icon: IconDashboard,
          external: false,
          target: false
        },
        {
          id: 'claims',
          title: 'Claims',
          type: 'item',
          url: '/claims',
          icon: IconReceiptRefund,
          external: false,
          target: false
        },
        {
          id: 'commissions',
          title: 'Commissions',
          type: 'item',
          url: '/commissions/earnings',
          icon: IconReceipt,
          external: false,
          target: false
        },
        {
          id: 'user',
          title: 'Profile',
          type: 'item',
          url: '/admin/profile',
          icon: AccountBoxIcon,
          external: false,
          target: false
        }
      ]
    }
  ]
};

export default adminMenu;
