import {
  GET_TRANSACTIONS,
  SEND_BULK_TRANSACATIONS,
  SEND_BULK_TRANSACATIONS_FAILED,
  SEND_SINGLE_TRANSACATIONS,
  SEND_SINGLE_TRANSACATIONS_FAILED,
  RESET_STATUS,
  UPDATE_TRANSACTION,
  GET_BULK_TRANSACTIONS,
  UPDATE_BULK_TRANSACTION,
  FILTER_TRANSACTIONS
} from './actions';

const initialTransactions = {
  transactions: null,
  bulkTransactions: null,
  filters: {
    bulk: 'all',
    individual: 'all'
  },
  error: null,
  bulkStatus: 'idle',
  singleStatus: 'idle',
  mode: ''
};

const transactionsReducer = (state = initialTransactions, action) => {
  const { transactions, transaction, bulkTransactions } = action;
  switch (action.type) {
    case GET_TRANSACTIONS:
      return { ...state, transactions, unfilteredIndividual: transactions };
    case GET_BULK_TRANSACTIONS:
      return { ...state, bulkTransactions, unfilteredBulk: bulkTransactions };
    case UPDATE_TRANSACTION:
      return {
        ...state,
        transactions: state.transactions.map((item) =>
          item.id === transaction.id ? transaction : item
        ),
        unfilteredIndividual: state.unfilteredIndividual.map((item) =>
          item.id === transaction.id ? transaction : item
        )
      };
    case UPDATE_BULK_TRANSACTION:
      return {
        ...state,
        bulkTransactions: state.bulkTransactions.map((item) =>
          item.id === transaction.id ? transaction : item
        ),
        unfilteredBulk: state.unfilteredBulk.map((item) =>
          item.id === transaction.id ? transaction : item
        )
      };
    case SEND_BULK_TRANSACATIONS:
      return {
        ...state,
        bulkTransactions: state.bulkTransactions
          ? [...state.bulkTransactions, transaction]
          : [transaction],
        bulkStatus: 'success',
        mode: action.mode
      };
    case SEND_BULK_TRANSACATIONS_FAILED:
      return {
        ...state,
        bulkStatus: 'failed',
        error: action.error,
        mode: action.mode
      };
    case SEND_SINGLE_TRANSACATIONS:
      return {
        ...state,
        transactions: state.transactions
          ? [...state.transactions, transaction]
          : [transaction],
        singleStatus: 'success'
      };
    case SEND_SINGLE_TRANSACATIONS_FAILED:
      return {
        ...state,
        singleStatus: 'failed',
        error: action.error
      };
    case FILTER_TRANSACTIONS: {
      const { filter, transactionType } = action;
      if (transactionType === 'bulk') {
        return {
          ...state,
          filters: { ...state.filters, bulk: filter },
          bulkTransactions:
            filter === 'all'
              ? state.unfilteredBulk
              : state.unfilteredBulk.filter((item) => item.verified === filter)
        };
      }
      if (transactionType === 'individual') {
        return {
          ...state,
          filters: { ...state.filters, individual: filter },
          transactions:
            filter === 'all'
              ? state.unfilteredIndividual
              : state.unfilteredIndividual.filter(
                  (item) => item.verified === filter
                )
        };
      }
      return state;
    }
    case RESET_STATUS: {
      return {
        ...state,
        bulkStatus: 'idle',
        singleStatus: 'idle',
        error: null,
        mode: ''
      };
    }
    default:
      return state;
  }
};

export default transactionsReducer;
