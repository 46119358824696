import { createSlice } from '@reduxjs/toolkit';
import { rootApi } from './store';

const groupRegistrationSlice = createSlice({
  name: 'groupRegisration',
  initialState: { group: '' },
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(
      rootApi.endpoints.registerGroup.matchFulfilled,
      (state, { payload }) => {
        state.group = payload;
      }
    );
  }
});

export default groupRegistrationSlice.reducer;
