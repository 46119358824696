import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import PrivacyPolicy from 'views/privacy-policy/';

// routes

import Loadable from 'components/Loadable';
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import MainRoutes from './MainRoutes';
import PublicRoutes from './PublicRoutes';
import ErrorRoutes from './ErrorRoutes';

const AuthLogin = Loadable(lazy(() => import('views/authentication/login')));
const LandingPage = Loadable(lazy(() => import('views/landing-page/')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: '/',
      element: (
        <GuestGuard>
          <MinimalLayout />
          <LandingPage />
        </GuestGuard>
      )
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />
    },
    LoginRoutes,
    AuthenticationRoutes,
    PublicRoutes,
    MainRoutes,
    ErrorRoutes
  ]);
}
