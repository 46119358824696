import logo from 'assets/images/logo5.png';

const Logo = () => (
  <div style={{ display: 'flex' }}>
    <span>
      <img src={logo} alt="logo" style={{ padding: 5 }} />
    </span>
    <h3>Liwaza</h3>
  </div>
);

export default Logo;
