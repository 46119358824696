import { configureStore } from '@reduxjs/toolkit';
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import optionsReducer from './optionsReducer';
import membersReducer from './groupsInfoReducer';
import usersReducer from './usersReducer';
import beneficiariesReducer from './beneficiariesReducer';
import paymentsReducer from './paymentsReducer';
import claimsReducer from './claimsReducer';
import transactionsReducer from './transactionsReducer';
import dependantsReducer from './dependantsReducer';
import imageViewerReducer from './imageViewerReducer';
import groupRegistrationReducer from './groupRegistrationReducer';
import { rootApi } from './store';

const store = configureStore({
  reducer: {
    [rootApi.reducerPath]: rootApi.reducer,
    customization: customizationReducer,
    snackbar: snackbarReducer,
    options: optionsReducer,
    groupsInfo: membersReducer,
    groupRegistration: groupRegistrationReducer,
    users: usersReducer,
    beneficiaries: beneficiariesReducer,
    payment: paymentsReducer,
    claims: claimsReducer,
    transactions: transactionsReducer,
    dependants: dependantsReducer,
    imageviewer: imageViewerReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    }).concat(rootApi.middleware)
});

export default store;
