import * as constants from './actions';

const initialState = {
  membersLoading: false,
  group: null,
  members: null, // []
  groups: null,
  groupsStatus: 'idle',
  error: null,
  uploadStatus: 'idle',
  uploadError: null,
  manualStatus: 'idle',
  manualAddError: null,
  mode: '',
  switchStatus: 'idle'
};

const membersReducer = (state = initialState, action) => {
  const { type, members, error, mode } = action;
  switch (type) {
    // case constants.GET_MEMBERS:
    //   return { ...state, membersLoading: true };
    case constants.GET_MEMBERS_SUCCESS:
      return { ...state, membersLoading: false, members, group: action.group };
    // case constants.GET_MEMBERS_FAILED:
    //   return { ...state, membersLoading: false, error };
    // case constants.GET_GROUPS_LOADING:
    //   return { ...state, groupsStatus: 'pending' };
    // case constants.GET_GROUPS_FAILED:
    //   return { ...state, groupsStatus: 'failed' };
    case constants.GET_GROUPS:
      return { ...state, groups: action.groups, groupsStatus: 'success' };
    case constants.ADD_GROUP_MEMBERS:
      return {
        ...state,
        manualStatus: mode === 'single' && 'success',
        uploadStatus: mode === 'upload' && 'success',
        members: state.members ? [...state.members, ...members] : members
      };
    case constants.ADD_MEMBERS_FAILED:
      return {
        ...state,
        uploadError: mode === 'upload' && error,
        manualAddError: mode === 'single' && error
      };
    case constants.SWITCH_MEMBERS: {
      const { details } = action;
      const newGroup = state.members.filter(
        (member) => !details.member_ids.includes(member.id)
      );
      return { ...state, members: newGroup, switchStatus: 'success' };
    }
    case constants.RESET_ADD_MEMBERS_STATUS:
      return { ...state, uploadStatus: 'idle', manualStatus: 'idle' };
    case constants.RESET_MEMBERS_ERRORS:
      return { ...state, uploadError: null, manualAddError: null };
    case constants.RESET_SWITCH_STATUS:
      return { ...state, switchStatus: 'idle' };
    default:
      return state;
  }
};

export default membersReducer;
