import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosServices from 'utils/axios';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const policyUrl = apiEndpoint;
const initialState = {
  error: null,
  policy: {},
  fetchStatus: 'idle'
};

export const fetchPolicy = createAsyncThunk('policy/fetch', async (data) => {
  const res = await axiosServices.get();
});
const policySlice = createSlice({
  name: 'policy',
  initialState,
  reducers: {},
  extraReducers: {}
});
