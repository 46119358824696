import { Typography } from '@mui/material';

import { useEffect, useReducer } from 'react';
import adminMenu from 'menu-items/adminMenu';
import regularMenu from 'menu-items/regularMenu';
import repMenu from 'menu-items/repMenu';
import groupAdminMenu from 'menu-items/groupAdminMenu';
import useAuth from 'hooks/useAuth';
import NavGroup from './NavGroup';

const initialState = {
  menuItems: regularMenu
};

const menuReducer = (state = initialState, action) => {
  const newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case 'REPRESENTATIVE':
      newState.menuItems = repMenu;
      return newState;
    case 'ADMINISTRATOR':
      newState.menuItems = adminMenu;
      return newState;
    case 'GROUP ADMINISTRATOR':
      newState.menuItems = groupAdminMenu;
      return newState;
    default:
      return state;
  }
};

const MenuList = () => {
  const { user } = useAuth();
  const [state, dispatch] = useReducer(menuReducer, initialState);
  const { menuItems } = state;

  useEffect(() => {
    if (user) {
      dispatch({ type: user.user_role });
    }
  }, [user]);

  const navItems = menuItems.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });
  return <div>{navItems}</div>;
};

export default MenuList;
