import * as constants from './actions';

const initialState = {
  isLoading: false,
  user: null,
  users: null,
  error: null,
  mode: '',
  addUsersStatus: 'idle'
};

const usersReducer = (state = initialState, action) => {
  const { user, users, error, mode } = action;
  switch (action.type) {
    case constants.SET_LOADING:
      return { ...state, isLoading: true };
    case constants.GET_PERSONAL_DETAILS: {
      return { ...state, user: action.data, isLoading: false };
    }
    case constants.GET_USER: {
      return { ...state, user, isLoading: false };
    }
    case constants.EDIT_USER:
      return { ...state, user };
    case constants.GET_USERS: {
      const modified = users.map((each) => {
        each.benefitOption = each.benefit
          ? Number(each.benefit.benefit_type.replace(/,/g, ''))
          : 0;
        each.premium = each.benefit ? Number(each.benefit.premium) : 0;
        return each;
      });
      return { ...state, users: modified, isLoading: false };
    }
    case constants.GET_USER_FAILED:
      return { ...state, error, isLoading: false };
    case constants.ADD_USERS:
      return {
        ...state,
        mode,
        error: null,
        addUsersStatus: 'success',
        users: state.users ? [...state.users, ...users] : [users]
      };
    case constants.ADD_USERS_FAILED:
      return { ...state, addUsersStatus: 'failed', mode, error };
    case constants.RESET_USERS_ERROR:
      return { ...state, error: null };
    case constants.RESET_CURRENT_USER:
      return { ...state, user: null };
    case constants.RESET_ADD_USERS_STATUS:
      return { ...state, addUsersStatus: 'idle' };
    default:
      return state;
  }
};

export default usersReducer;
