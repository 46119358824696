import AppBar from 'components/material-components/AppBar';
import Feedback from 'layout/feedback';
import { Outlet } from 'react-router-dom';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
  <>
    <AppBar />

    <Outlet />
    <Feedback />
  </>
);

export default MinimalLayout;
