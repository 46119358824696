import { useEffect, useState, useReducer } from 'react';
import { useCurrentUserQuery } from 'store/store';

const initialState = {
  role: null,
  hasMemberStatus: false // to capture all roles that are primarily members
};

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADMINISTRATOR':
      return { ...state, role: 'admin' };
    case 'GROUP ADMINISTRATOR':
      return { ...state, role: 'groupAdmin' };
    case 'REPRESENTATIVE':
      return { ...state, role: 'groupRep', hasMemberStatus: true };
    case 'MEMBER':
      return { ...state, role: 'member', hasMemberStatus: true };
    default:
      return state;
  }
};
export default function useRoles() {
  const { data: user } = useCurrentUserQuery();
  const [state, dispatch] = useReducer(rolesReducer, initialState);

  useEffect(() => {
    if (user) {
      dispatch({ type: user.user_role });
    }
  }, [user]);

  return state;
}
