import { lazy } from 'react';

import Loadable from 'components/Loadable';

import MinimalLayout from 'layout/MinimalLayout';

const GroupRegistration = Loadable(
  lazy(() => import('views/groups/registration'))
);

const ClaimRegistration = Loadable(
  lazy(() => import('views/claims/claims-registration'))
);

const FAQs = Loadable(lazy(() => import('views/faqs')));

const PublicRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/create-group',
      element: <GroupRegistration />
    },
    {
      path: '/claim',
      element: <ClaimRegistration />
    },
    {
      path: 'faqs',
      element: <FAQs />
    }
  ]
};

export default PublicRoutes;
