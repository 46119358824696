import useRoles from 'hooks/useRoles';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function MemberProfileGuard({ children }) {
  const { role } = useRoles();
  const navigate = useNavigate();
  useEffect(() => {
    if (role && (role === 'admin' || role === 'groupAdmin'))
      navigate('/admin/profile', { replace: true });
    // eslint-disable-next-line
  }, [role]);

  return children;
}
