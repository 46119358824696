import { lazy } from 'react';

// project imports

import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'components/Loadable';
import GuestGuard from 'utils/route-guard/GuestGuard';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/authentication/login')));

const AuthRegister = Loadable(
  lazy(() => import('views/authentication/register'))
);

const ForgotPassword = Loadable(
  lazy(() => import('views/authentication/password-change/forgot-password'))
);

const ResetPassword = Loadable(
  lazy(() => import('views/authentication/password-change/ResetPassword'))
);

const ValidateToken = Loadable(
  lazy(() => import('views/authentication/password-change/PasswordToken'))
);

const LoginRoutes = {
  path: '/',
  element: (
    <GuestGuard>
      <MinimalLayout />
    </GuestGuard>
  ),
  children: [
    {
      path: '/login',
      element: <AuthLogin />
    },
    {
      path: '/register',
      element: <AuthRegister />
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />
    },
    {
      path: '/validate-token',
      element: <ValidateToken />
    },
    {
      path: '/reset-password',
      element: <ResetPassword />
    }
  ]
};

export default LoginRoutes;
